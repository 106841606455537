var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('card',{attrs:{"title":"Policies","add-button-tooltip":"Create Policy"},on:{"click:add":function($event){return _vm.$router.push({ name: 'policy-create' })}}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"item-key":"id","dense":"","loading":_vm.loading,"items-per-page":_vm.size,"page":_vm.page,"server-items-length":_vm.totalItems,"hide-default-footer":""},on:{"update:options":function($event){return _vm.optionsUpdated($event)}},scopedSlots:_vm._u([{key:"headerCell",fn:function(ref){
var header = ref.header;
return [_c('span',{staticClass:"subheading font-weight-light text-success text--darken-3",domProps:{"textContent":_vm._s(header.text)}})]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"policy-link",attrs:{"to":{ name: 'policy', params: { id: item.id } }}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.enabled",fn:function(ref){
var item = ref.item;
return [_c('v-simple-checkbox',{attrs:{"hide-details":"auto","color":"darkgrey","value":item.enabled},on:{"input":function($event){return _vm.enableItem(item, $event)}}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"small":""},on:{"click":function($event){$event.stopPropagation();return _vm.deleteItem(item)}}},[_vm._v(" > mdi-delete ")])]}}])},[(_vm.apiFault)?_c('template',{slot:"no-data"},[_vm._v(" Unable to fetch results from the API ")]):_vm._e()],2),_c('v-pagination',{attrs:{"length":_vm.numberOfPages,"total-visible":"20"},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}}),_c('v-select',{attrs:{"id":"itemsPerPage","label":"Policies per page:","items":[10, 20, 50]},model:{value:(_vm.size),callback:function ($$v) {_vm.size=$$v},expression:"size"}})],1),_c('ec-snack-bar',{attrs:{"options":_vm.snackbarOptions}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }